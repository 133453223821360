var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"ProfileView"},[_c('div',{staticClass:"ProfileView__wrapper"},[_c('div',{staticClass:"ProfileView__userContent"},[_c('v-avatar',{staticClass:"UserAvatar",attrs:{"size":"144"},on:{"click":function($event){return _vm.openUserAvatarModal()}}},[(null === _vm.foundUser || null === _vm.foundUser.profilePicturePath)?_c('img',{attrs:{"src":'https://eu.ui-avatars.com/api/?background=random&name=' +
            _vm.user.firstname +
            '+' +
            _vm.user.lastname,"alt":_vm.user.fullName}}):_c('img',{attrs:{"src":_vm.currentProfilePresignedUrl,"alt":_vm.user.fullName}})])],1),_c('div',{staticClass:"ProfileView__userContent"},[_c('h1',[_vm._v(" Bonjour, "),_c('span',[_vm._v(_vm._s(_vm.user.fullName))])])]),_c('div',{staticClass:"ProfileView__content"},[_c('MediumButtonSlot',{on:{"click":_vm.toggleChangePassword}},[_vm._v("MODIFIER MON MOT DE PASSE")]),_c('v-form',{directives:[{name:"show",rawName:"v-show",value:(_vm.isChangePassword),expression:"isChangePassword"}],ref:"form",staticClass:"ProfileView__form",on:{"submit":function($event){$event.preventDefault();return _vm.changePassword()}}},[_c('v-text-field',{ref:"field",attrs:{"autocomplete":"","append-icon":_vm.showActualPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showActualPassword ? 'text' : 'password',"outlined":"","clearable":"","label":"Mot de passe actuel","rules":[_vm.$rules.required, _vm.$rules.min8Chars],"error-messages":_vm.errorMessage},on:{"click:append":function($event){_vm.showActualPassword = !_vm.showActualPassword},"change":_vm.onChangeMDP},model:{value:(_vm.form.actualPassword),callback:function ($$v) {_vm.$set(_vm.form, "actualPassword", $$v)},expression:"form.actualPassword"}}),_c('v-text-field',{attrs:{"autocomplete":"","append-icon":_vm.showPassword ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPassword ? 'text' : 'password',"color":"#2C0703","outlined":"","clearable":"","label":"Nouveau mot de passe","rules":[
            _vm.$rules.required,
            _vm.$rules.min8Chars,
            _vm.rules.notSamePassword,
            _vm.$rules.isPasswordValidFormat,
            _vm.rules.samePassword ]},on:{"click:append":function($event){_vm.showPassword = !_vm.showPassword}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('v-text-field',{attrs:{"append-icon":_vm.showPasswordRepeat ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.showPasswordRepeat ? 'text' : 'password',"color":"#2C0703","outlined":"","clearable":"","label":"Confirmez le nouveau mot de passe","rules":[_vm.$rules.required, _vm.$rules.min8Chars, _vm.rules.samePassword]},on:{"click:append":function($event){_vm.showPasswordRepeat = !_vm.showPasswordRepeat}},model:{value:(_vm.form.passwordRepeat),callback:function ($$v) {_vm.$set(_vm.form, "passwordRepeat", $$v)},expression:"form.passwordRepeat"}}),_c('div',{staticClass:"formButtons"},[_c('HighButtonSlot',{attrs:{"_disabled":!_vm.isPasswordValid,"_type":"'submit'"}},[_vm._v("Changer le mot de passe")])],1)],1)],1)]),(_vm.isUserAvatarModalOpen)?_c('Modal',{attrs:{"title":"Modifier la photo de profil"},on:{"close":function($event){return _vm.closeUserAvatarModal()},"validate":function($event){return _vm.savePicture()}}},[_c('div',[(null !== _vm.url)?_c('v-img',{staticClass:"profilePic",attrs:{"target":"_blank","src":_vm.url}}):_c('div',[(_vm.currentProfilePresignedUrl === null)?_c('img',{staticClass:"imgPlaceholder profilePic",attrs:{"src":'https://eu.ui-avatars.com/api/?background=random&name=' +
            _vm.user.firstname +
            '+' +
            _vm.user.lastname,"alt":_vm.user.fullName}}):_c('img',{staticClass:"profilePic",attrs:{"src":_vm.currentProfilePresignedUrl,"alt":_vm.user.fullName}})]),_c('div',{staticClass:"fileInput mt-2"},[_c('div',[_c('div',{class:null !== _vm.image
                ? 'file_input_personalized active'
                : 'file_input_personalized',on:{"click":_vm.selectFile}},[(null === _vm.image)?_c('v-icon',{attrs:{"medium":"","center":"","color":"#2c0703"}},[_vm._v(" mdi-paperclip ")]):_vm._e()],1)]),_c('input',{ref:"fileInput",staticClass:"d-none",attrs:{"type":"file","accept":"image/png, image/jpeg, image/jpg"},on:{"change":_vm.updateFile}}),_c('div',[_c('MediumButtonSlot',{on:{"click":_vm.selectFile}},[_vm._v(" "+_vm._s(null === _vm.image ? _vm.$t("add_pictures") : "Modifier la photo")+" ")])],1),_c('LowButtonSlot',{staticClass:"ml-1",on:{"click":function($event){return _vm.deleteImage()}}},[_vm._v(" Supprimer la photo ")])],1)],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }